import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    ViewEncapsulation
} from '@angular/core';

@Component({
    selector: 'ui-image, a[ui-image]',
    template: `
        <figure *ngIf="src">
            <vb-responsive-image
                [alt]="alt"
                [src]="src"
                [service]="service"
                [sizes]="sizes"
                [lazyload]="lazyload"
                [animate]="animate"
                [priority]="priority"
                [fit]="fit"
                [focus]="focus"
            ></vb-responsive-image>

            <ng-content select="ui-image-caption"></ng-content>
        </figure>
    `,
    styleUrls: ['./image.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UIImageComponent {
    @Input() src: string | null | undefined;

    @Input() alt!: string;
    @Input() caption!: string;
    @Input() lazyload = true;
    @Input() animate = true;
    @Input() priority = false;
    @Input() fit = 'fill';
    @Input() focus = 'center';

    @Input() service = 'imgix';
    @Input() sizes!: { [key: string]: number[] };

    @Input()
    get expanded() {
        return this._expanded;
    }
    set expanded(value) {
        this._expanded = coerceBooleanProperty(value);
    }
    private _expanded = false;

    @Input()
    get static() {
        return this._static;
    }
    set static(value: boolean | string | undefined) {
        this._static = coerceBooleanProperty(value);
    }
    private _static = false;

    @Input()
    get plain() {
        return this._plain;
    }
    set plain(value) {
        this._plain = coerceBooleanProperty(value);
    }
    private _plain = false;

    @HostBinding('attr.expanded') get attrIsExpanded() {
        return this._expanded || null;
    }

    @HostBinding('attr.static') get attrIsStatic() {
        return this._static || null;
    }

    @HostBinding('attr.plain') get attrIsPlain() {
        return this._plain || null;
    }

    constructor(
        private cdr: ChangeDetectorRef,
        private _elementRef: ElementRef
    ) {
        this._getHostElement().classList.add('ui-image');
    }

    markForCheck() {
        this.cdr.markForCheck();
    }

    private _getHostElement() {
        return this._elementRef.nativeElement;
    }
}
