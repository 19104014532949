<picture>
    <source
        *ngFor="let source of sources; trackBy: trackBySource"
        [attr.media]="'(min-width:' + source.minWidth + 'px)'"
        [attr.srcset]="source.srcset"
    />

    <img
        [src]="default"
        [alt]="alt"
        [attr.fetchpriority]="priority ? 'high' : 'auto'"
        [attr.loading]="lazyload ? 'lazy' : 'eager'"
        (load)="loaded = true"
    />
</picture>
