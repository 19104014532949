import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VbResponsiveImageComponent } from './responsive-image.component';

// @Injectable()
// class LazyLoadImageHooks extends IntersectionObserverHooks {
//     isDisabled() {
//         return isPlatformServer(this.platformId) && !this.isBot();
//     }
// }

@NgModule({
    declarations: [VbResponsiveImageComponent],
    imports: [CommonModule /*LazyLoadImageModule*/],
    providers: [
        // { provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }
    ],
    exports: [VbResponsiveImageComponent]
})
export class VbResponsiveImageModule {}
