import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VbResponsiveImageModule } from '@vb/common/responsive-image';

import { UIImageCaptionComponent, UIImageCaptionItemDirective } from './image-caption.component';
import { UIImageComponent } from './image.component';

@NgModule({
    imports: [CommonModule, VbResponsiveImageModule],
    declarations: [UIImageComponent, UIImageCaptionComponent, UIImageCaptionItemDirective],
    exports: [UIImageComponent, UIImageCaptionComponent, UIImageCaptionItemDirective]
})
export class UiImageModule {}
