import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
} from '@angular/core';

const BREAKPOINTS = {
  lg: 1200,
  md: 960,
  sm: 600,
  xs: 0,
};

const DENSITY = [1, 2];

const SIZE_VALUE_MAP = ['w', 'h'];

@Component({
  selector: 'vb-responsive-image',
  templateUrl: './responsive-image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'ui-responsive-image',
    '[class.loaded]': 'loaded',
    '[class.animate]': 'shouldAnimate',
  },
})
export class VbResponsiveImageComponent implements OnChanges {
  @Input() alt!: string;
  @Input() service = 'imgix';
  @Input() fit = 'fill';
  @Input() focus = 'center';
  @Input() lazyload = true;
  @Input() animate = true;
  @Input() priority = false;
  default: string | undefined = undefined;
  sources: any;
  loaded = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private _cdr: ChangeDetectorRef,
  ) {}

  private _src!: string;

  @Input()
  get src() {
    return this._src;
  }

  set src(value) {
    if (value.substring(0, 2) === '//') {
      value = 'https:' + value;
    }

    if (value.startsWith('https://downloads.ctfassets.net')) {
      value = value.replace(
        'https://downloads.ctfassets.net',
        'https://images.ctfassets.net',
      );
    }

    if (this._src === value) {
      return;
    }

    this._src = value;
  }

  private _sizes!: { [key: string]: number[] };

  @Input()
  get sizes() {
    return this._sizes;
  }

  set sizes(value) {
    if (this._sizes === value) {
      return;
    }

    this._sizes = value;
  }

  get shouldAnimate() {
    return this.animate && isPlatformBrowser(this.platformId);
  }

  ngOnChanges(changes: any) {
    if (changes.sizes || changes.src) {
      this._updateSources();
    }
  }

  trackBySource(_i: number, source: any) {
    return source.minWidth;
  }

  private _generateDefault(): string | undefined {
    const defaultDimensions =
      this.sizes['sm'] || this.sizes['md'] || this.sizes['xs'];
    const url = new URL(this.src);
    const query = new URLSearchParams(url.searchParams);

    query.append('fm', 'png');
    query.append('q', '60');

    defaultDimensions.forEach((dimension, index) => {
      query.append(SIZE_VALUE_MAP[index], Math.round(dimension / 2).toString());
    });

    url.search = query.toString();

    return url.toString();
  }

  private _updateSources() {
    if (!this.sizes) {
      return;
    }

    this.default = this._generateDefault();

    this.sources = (Object.keys(BREAKPOINTS) as (keyof typeof BREAKPOINTS)[])
      .filter((breakpoint) => !!this.sizes[breakpoint])
      .map((breakpoint) => {
        const minWidth = BREAKPOINTS[breakpoint];
        const dimensions = this.sizes[breakpoint];

        const srcset = DENSITY.map((multiplier) => {
          const url = new URL(this.src);
          const query = new URLSearchParams(url.searchParams);

          query.append('fm', 'webp');
          query.append('q', '90');

          if (this.service === 'sanity') {
            query.append('fit', this.fit);
          }

          dimensions.forEach((dimension, index) => {
            query.append(
              SIZE_VALUE_MAP[index],
              (dimension * multiplier).toString(),
            );
          });

          url.search = query.toString();

          return url.toString() + ' ' + multiplier + 'x';
        }).join(',');

        return {
          minWidth,
          srcset,
        };
      });

    this._cdr.markForCheck();
  }
}
